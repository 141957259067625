import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import FeaturedServices from "./components/FeaturedServices/FeaturedServices";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ImageSlider from "./components/Slider/ImageSlider";
import ServicePage from "./components/ServicePage/ServicePage";
import "./App.css";
import AboutUs from "./components/AboutUs/AboutUs";
import Cart from "./components/Cart/Cart";
import Catalog from "./components/Catalog/Catalog";
import { CartProvider } from "./components/CartContext/CartContext";
import SiteWideChatBoxIframe from "./SiteWideChatboxIframe";

const RouteChangeHandler = () => {
  const location = useLocation();
  useEffect(() => {
    const currentOrigin = window.location.origin; // Gets the current origin (e.g., http://localhost:3000)
    const allowedOrigins = ['https://bricksoft.io', 'https://bricksoft.ai', 'http://localhost:3000'];
    
    // Send postMessage only to the current origin
    if (allowedOrigins.includes(currentOrigin)) {
      window.parent.postMessage({ pathname: location.pathname }, currentOrigin);
    }
  }, [location]);
  return null;
};


function App({
  customStyles,
  sliderImages,
  productPageStyles,
  navigation,
  footerStyles,
  websiteId,
}) {
  const [customStylesState, setCustomStylesState] = useState(customStyles);
  const [sliderImagesState, setSliderImagesState] = useState(sliderImages);
  const [footerStylesState, setFooterStylesState] = useState(footerStyles);
  const [productPageStylesState, setProductPageStylesState] = useState(productPageStyles);

  useEffect(() => {
    function handleMessage(event) {
      const data = event.data;
      if (
        data &&
        typeof data === "object" &&
        data.options &&
        data.sliderImages &&
        data.productPageStyles &&
        data.footerStyles
      ) {
        setCustomStylesState(data.options);
        setSliderImagesState(data.sliderImages);
        setProductPageStylesState(data.productPageStyles);
        setFooterStylesState(data.footerStyles);
      }
    }
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <CartProvider>
      <Router>
        <RouteChangeHandler />
        <Routes>
          <Route
            path="/chat-box"
            element={<SiteWideChatBoxIframe websiteId={websiteId} />}
          />
          <Route
            path="/"
            element={
              <>
                <Header />
                <Routes>
                  <Route
                    index
                    element={
                      <>
                        <ImageSlider
                          sliderImages={sliderImagesState}
                          captionText={customStylesState.captionText}
                          buttonText={customStylesState.buttonText}
                          imageSlidePosition1={customStylesState.imageBgPosition1}
                          imageSlidePosition2={customStylesState.imageBgPosition2}
                        />
                        <FeaturedServices />
                      </>
                    }
                  />
                  <Route path="service/:serviceId" element={<ServicePage styles={productPageStylesState} />} />
                  <Route path="cart" element={<Cart />} />
                  <Route path="contact" element={<AboutUs />} />
                  <Route path="catalog" element={<Catalog />} />
                </Routes>
                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;